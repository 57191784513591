import Blog01 from "./Blog01";
import Terma from "./terma";
import Penafian from "./penafian";
import Privasi from "./privasi";
import BlogDetails01 from "./BlogDetails01";
import Contact from "./Contact";
import Explore02 from "./Explore02";
import HelpCenter from "./HelpCenter";
import Home01 from "./Home01";
import ItemDetails01 from "./ItemDetails01";
import Maulid from "./maulid";
import Kelas from "./kelasngaji";





const routes = [
  { path: '/', component: <Home01 />},
  { path: '/senarai-institusi', component: <Explore02 />},
  { path: '/institusi/:slug', component: <ItemDetails01 />},
  { path: '/mengenai-aljariyah', component: <HelpCenter />},
  { path: '/berita-aktiviti', component: <Blog01 />},
  { path: '/terma', component: <Terma />},
  { path: '/penafian', component: <Penafian />},
  { path: '/privasi', component: <Privasi />},
  { path: '/A-COLLABORATION-TO-EMPOWER-RELIGIOUS-INSTITUTIONS', component: <BlogDetails01 />},
  { path: '/institusi/Masjid-Sri-Sendayan/INFAQ-IFTAR', component: <Maulid />},
  { path: '/institusi/Masjid-Bandar-Sg-Long/KELAS-MENGAJI-DEWASA', component: <Kelas />},
  { path: '/contact', component: <Contact />}


]

export default routes;