

import img1 from '../images/slider/onestop.png'
import img2 from '../images/slider/platformPintar.png'
import img3 from '../images/slider/planInfaq.png'
import img4 from '../images/slider/bantuan.png'
import img5 from '../images/slider/logistik.png'
import img6 from '../images/slider/bisnesGrowth.png'

const dataBanner2 = [
    {
        id: 1,
        
        heading: 'One Stop Center ',
        desc: 'Platform amal jariyah Islam bersepadu yang menghubungkan institusi agama dan pendidikan, masyarakat setempat dan pihak berkepentingan dalam platform yang sama.',
        img: img1,
    },
    {
        id: 2,
        
        heading: 'Platform Pintar',
        desc: 'Pengesahan digital untuk pemberian bantuan tanpa penglibatan pihak ketiga.',
        img: img2,
    },
    {
        id: 3,
        
        heading: 'Rancang Infaq Anda ',
        desc: 'Infaq secara berkala dengan menetapkan institusi, jumlah infaq dan waktu menginfaq. atau secara one-off.',
        img: img3,
    },
    {
        id: 4,
        
        heading: 'Bantuan Tanpa Had Pilihan ',
        desc: 'Mencakupi yang bukan Islam dalam pemberian bantuan.',
        img: img4,
    },
    {
        id: 5,
        
        heading: 'Pengurangan Kos Logistik ',
        desc: 'Anggaran pengurangan kos sehingga 20% dalam perkhidmatan yang disediakan.',
        img: img5,
    },
    {
        id: 6,
        
        heading: 'Pertumbuhan Bidang Niaga ',
        desc: 'Persaingan perniagaan yang sihat kepada pembekal perkhidmatan dalam Al-Jariyah.',
        img: img6,
    },
    

]

export default dataBanner2;