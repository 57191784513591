import React from 'react';
import PageTitle from '../components/pagetitle/PageTitle';
BlogDetails01.propTypes = {
    
};

function BlogDetails01(props) {
    return (
        <div>

            <PageTitle sub='Blog' title='Terma & Syarat' />
            
            <section className="tf-blog-detail">
                <div className="tf-container">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="detail-inner">
                                
                                <div className="content-inner" style={{textAlign:"justify"}}>
                                    {/* <h6 className="title">What Is The Most Fun Thing To Become A Designer?</h6> */}
                                    <br/><p >Selamat datang ke aplikasi Al- Jariyah. Penggunaan laman web dan aplikasi mudah alih Al-Jariyah ("Platform Al-Jariyah") adalah tertakluk kepada terma-terma dan syarat-syarat tertentu. Jika anda terus mengakses dan melayari Platform Al-Jariyah, anda telah menerima dan bersetuju dengan terma dan syarat penggunaan ini.</p>
                                    
                                </div>
                                
                                <div className="content-inner" style={{textAlign:"justify"}}>
                                    <h5 className="title">A. Definisi Al-Jariyah</h5><br/>
                                    <h6 className="title">1. "Al-Jariyah"</h6>
                                    <p>Bermaksud nama aplikasi mudah alih ("Platform Al-Jariyah").</p><br/>
                                    <h6 className="title">2. "Infaq"</h6>
                                    <p>Bermaksud sedekah atau sumbangan yang dilakukan dalam Platform Al-Jariyah.</p><br/>
                                    
                                    <h6 className="title">3. "Waqaf"</h6>
                                    <p>Bermaksud sumbangan wagaf yang dilakukan dalam Platform Al-Jariyah.</p><br/>

                                    <h6 className="title">4. "Pengguna"</h6>
                                    <p>Bermaksud individu pemegang akaun yang telah didaftarkan melalui Platform Al-Jariyah.</p><br/>

                                    <h6 className="title">5. "Institusi"</h6>
                                    <p>Bermaksud organisasi yang telah disenarakan ke dalam Platform Al-Jariyah ini untuk menerima sumbangan daripada masyarakat umum ata sebagai pembekal perkhidmatan bagi lain-lain perkhidmatan yang disediakan.</p><br/>

                                    <h6 className="title">6. "Peranti"</h6>
                                    <p>Bermaksud telefon pintar, tablet, komputer riba dan seumpamanya.</p><br/>

                                    <h6 className="title">7. "Support Al-Jariyah"</h6>
                                    <p>Bermaksud perkhidmatan sokongan yang disediakan di dalam Platform Al-Jariyah</p><br/>
                                    
                                    <h6 className="title">8. "Akaun Al-Jariyah"</h6>
                                    <p>Bermaksud akaun maya/e-wallet institusi atau pengguna yang diberikan setelah mendaftar dalam Platform Al-Jariyah ini.</p><br/>
                                </div>
                                
                                
                            </div>                        
                        </div>                                     
                    </div>
                </div>
            </section>
            
        </div>
    );
}

export default BlogDetails01;