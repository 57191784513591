

import img1 from '../images/blog/anf.png'
const dataBlog = [
    {
        id: 1,
        img: img1,
        title: 'A COLLABORATION TO EMPOWER RELIGIOUS INSTITUTIONS',
        cate: 'Berita',
        user: 'By Author',
        time: 'Dec 20, 2023',
        text :'DagangTEK Sdn Bhd has signed a collaboration agreement with ANF Trade & Communications on the 20th of December 2023. This Collaboration Agreement signifies the strategic alliance between these ...'
    },
    

   
]

export default dataBlog;