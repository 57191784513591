import React from 'react';
import PageTitle from '../components/pagetitle/PageTitle';
import img1 from '../assets/images/mockup_TranparentBG.png'
import HotPick from '../components/hotpick/HotPick';
import dataHotpick from '../assets/data/data-perintis';
import { Link } from 'react-router-dom';

// Explore02.propTypes = {
    
// };



function Explore02(props) {
    return (
        <div className='page-explore'>
            <PageTitle sub='Explore' title='Senarai Institusi' />
            

            <section className="tf-baner-live-auction style-2">
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner-liver-auction-wrap">
                                <div className="content">
                                    <div className="heading">
                                        <h2 className="title">Jom Sertai Kami</h2>
                                        <span className="label"><Link to='https://cp.al-jariyah.com/register' target='_blank' style={{color:"white"}}>Sertai Kami</Link></span>
                                    </div>
                                    {/* <div className="price">
                                        <span className="icon"><i className="fab fa-ethereum"></i></span>
                                        <span>5 ETH</span>
                                    </div> */}
                                    <p className="sub-heading">sebagai perintis Al-Jariyah</p>
                                </div>
                                <div className="image">
                                    <img src={img1} alt="AlJariyah" className="img1" style={{width:"45%"}}/>
                                    {/* <img src={img2} alt="AlJariyah" className="img2" style={{width:"100px"}}/> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <HotPick data={dataHotpick} />
            
        </div>
    );
}

export default Explore02;