import React from 'react';
import PropTypes from 'prop-types';

import { Navigation,Pagination, Scrollbar, A11y   } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import img2 from '../../assets/images/slider/slider-2.png'

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import './styles.scss'
import { Link } from 'react-router-dom';

Banner01.propTypes = {
    data : PropTypes.array,
};

function Banner01(props) {
    const {data} = props;
    
    return (
        <section className="tf-slider" style={{paddingBottom:"50px"}}>
                <div className="tf-container">
                <div className="row">
                    <div className="col-md-12">
                        <Swiper
                            modules={[Navigation, Scrollbar,Pagination, A11y]}
                            spaceBetween={0}
                            slidesPerView={1}
                            className="slider-home home1"
                            loop={false}
                            navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
                            pagination={{ clickable: true }}
                        >
                            {data.map((idx, index) => (
                                <SwiperSlide key={idx.id}>
                                    {index === 0 ? ( // Check if it's the second slide (index 1)
                                        <div className="tf-slider-item" style={{marginLeft:"50px", marginRight:"50px"}}>
                                            <div className="content-inner" style={{marginBottom:"30px"}}>
                                                <h1 className="heading">{idx.heading}</h1>
                                                <p className="sub-heading">{idx.desc}</p>
                                                <div className="btn-slider ">
                                                    <Link to="https://play.google.com/store/apps/details?id=com.al_jariyah&hl=ms&gl=US" target='_blank' className="tf-button style-2">
                                                        Muat Turun Sekarang
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="image" style={{marginBottom:"20px"}}>
                                                <div className="img-slider-main ani5">
                                                    <img src={idx.img} alt="AlJariyah" style={{maxWidth:"90%"}}/>
                                                </div>
                                                {index < data.length - 1 && (
                                                    <>
                                                        <img src={img2} alt="AlJariyah" className="img-slider-2 ani4" />
                                                        <img src={img2} alt="AlJariyah" className="img-slider-3 ani5" />
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        
                                    ) : (
                                        <div className="tf-slider-item" style={{border:"inset", paddingTop:"0px"}} >
                                            {/* Default image for larger screens */}
                                            <img
                                                className="desktop-image"
                                                src={idx.img}
                                                alt="AlJariyah"
                                                style={{ width: "100%", objectFit: "cover" }}
                                            />

                                            {/* Mobile image for smaller screens */}
                                            <img
                                                className="mobile-image"
                                                src={idx.imgMobile}
                                                alt="AlJariyah"
                                                style={{ width: "100%", objectFit: "cover" }}
                                            />
                                        </div>
                                        
                                    )}
                                </SwiperSlide>
                            ))}
                            <div className="swiper-button-next"></div>
                            <div className="swiper-button-prev"></div>
                        </Swiper>
                    </div>
                </div>

                </div>
            </section>
    );
}

export default Banner01;