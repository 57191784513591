import React from 'react';
import dataBanner from '../assets/data/data-banner';
import dataManfaat from '../assets/data/data-banner-2';
import dataCategory from '../assets/data/data-category';
import Manfaat from '../components/banner/Banner03';
import Banner01 from '../components/banner/Banner01';
import Category from '../components/category/Category';
import CTA from '../components/cta';
import LiveAutions from '../components/liveautions/LiveAuctions';
import dataLiveaution from '../assets/data/data-category';

function Home01(props) {

    return (
        <div className="home-1">
            <div id='page'>
                <Banner01 data={dataBanner} />

                {/* <Category data={dataCategory} /> */}

                <LiveAutions data={dataCategory} />

                <Manfaat data={dataManfaat} />

                <CTA data={dataManfaat} />

                {/* <Explore data={dataExplore} /> */}
            </div>
        </div>

    );
}

export default Home01;