import React , { useState ,useEffect } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';

import logo from '../../assets/images/logo/logo_dark.png';
import logodark from '../../assets/images/logo/logo.png';
import playstore from '../../assets/images/logo/playstore.png';

import './styles.scss'



function Footer(props) {

    const [productLink] = useState([
        {
            title: 'Spot',
            path: '/spot'
        },
        {
            title: 'Inverse Perpetual',
            path: '#'
        },
        {
            title: 'USDT Perpetual',
            path: '#'
        },
        {
            title: 'Exchange',
            path: '#'
        },
        {
            title: 'Launchpad',
            path: '#'
        },
        {
            title: 'Binance Pay',
            path: '#'
        },
    ]);
    const [servicesLink] = useState([
        {
            title: 'Buy Crypto',
            path: '#'
        },
        {
            title: 'Markets',
            path: '#'
        },
        {
            title: 'Tranding Fee',
            path: '#'
        },
        {
            title: 'Affiliate Program',
            path: '#'
        },
        {
            title: 'Referral Program',
            path: '#'
        },
        {
            title: 'API',
            path: '#'
        },
    ]);


    const [listSocial] = useState([
        {
            icon: 'icon-facebook-f',
            path: '#'
        },
        {
            icon: 'icon-instagram',
            path: '#'
        },
        {
            icon: 'icon-youtube',
            path: '#'
        },
        {
            icon: 'icon-twitter',
            path: '#'
        },
    ])
    

    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
  
    useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener("scroll", toggleVisibility);
  
      return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (

        <footer className="footer">
                <div className="tf-container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-3 col-md-3">
                            <div className="widget widget-infor">
                                <div className="logo">
                                    <img id="logo_footer" className='logo-dark' src={logo} alt="AlJariyah" style={{width:"100px"}} />
                                    <img id="logo_footer" className='logo-light' src={logodark} alt="AlJariyah" style={{width:"100px"}} />
                                </div>
                                <p className="content">Al-Jariyah merupakan gerbang utama ke semua perkhidmatan berkaitan Islam yang ditawarkan oleh institusi berdaftar di bawah satu platform.</p>
                                {/* <ul className="social-item">
                                    <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                                    <li><Link to="#"><i className="fab fa-facebook"></i></Link></li>
                                    <li><Link to="#"><i className="fab fa-telegram-plane"></i></Link></li>
                                    <li><Link to="#"><i className="fab fa-youtube"></i></Link></li>
                                    <li><Link to="#"><i className="icon-fl-tik-tok-2"></i></Link></li>
                                    <li><Link to="#"><i className="icon-fl-vt"></i></Link></li>
                                </ul> */}
                                <p className="copy-right">Copyright © 2024 Al-Jariyah. All Rights Reserved.

                                </p>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 col-md-5">
                            <div className="widget widget-menu">
                                <div className="menu menu-1">
                                    <h6 className="widget-title">Maklumat Al-Jariyah</h6>
                                    <ul >
                                        <li><Link to="/mengenai-aljariyah">Mengenai Al-Jariyah</Link></li>
                                        <li><Link to="/terma">Terma & Syarat</Link></li>
                                        
                                    </ul>
                                </div>
                                {/* <div className="menu menu-2">
                                    <h6 className="widget-title">Stats</h6>
                                    <ul >
                                        <li><Link to="/ranking">Ranking</Link></li>
                                        <li><Link to="/login">Login</Link></li>
                                        <li><Link to="/create">Create</Link></li>
                                    </ul>
                                </div> */}
                                <div className="menu menu-3">
                                    <h6 className="widget-title">Ketahui Lagi</h6>
                                    <ul >
                                        <li><Link to="/privasi">Dasar Privasi</Link></li>
                                        <li><Link to="/penafian">Penafian</Link></li>
                                    </ul>
                                </div>
                                <div className="menu menu-4">
                                    <h6 className="widget-title">Pautan Pantas</h6>
                                    <ul >
                                        <li><Link to="/senarai-institusi">Senarai Institusi</Link></li>
                                        <li><Link to="/berita-aktiviti">Berita & Aktiviti</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-4">
                            <div className="widget widget-subcribe">
                                <h6 className="widget-title">Muat Turun</h6>
                                <p className="content">Aplikasi Al-Jariyah.</p>
                                <Link to='https://play.google.com/store/apps/details?id=com.al_jariyah&hl=ms&gl=US' target='_blank'> <img id="logo_footer" className='logo-light' src={playstore} alt="dtek" style={{width:"60%", display:"block"}} /> </Link>
                                
                            </div>
                        </div>
                    </div>
                </div>

                {
                isVisible && 
                <Link onClick={scrollToTop}  to='#' id="scroll-top"></Link>
            }
           </footer>
    );
}

export default Footer;