import React from 'react';
import PropTypes from 'prop-types';
import PageTitle from '../components/pagetitle/PageTitle4';
import { Link } from 'react-router-dom';

import img1 from '../assets/images/blog/anf-details.png'
import img2 from '../assets/images/blog/anf-details-1.png'
import img3 from '../assets/images/blog/anf-details-2.png'
import img4 from '../assets/images/blog/anf-details-3.png'
import avt1 from '../assets/images/blog/author.png'

BlogDetails01.propTypes = {
    
};

function BlogDetails01(props) {
    return (
        <div>

            <PageTitle sub='Senarai Institusi' title='Hebahan Institusi' />
            
            <section className="tf-blog-detail">
                <div className="tf-container">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="detail-inner">
                                <div className="content-top">
                                    <h4 className="title">INFAQ IFTAR </h4>
                                    <div className="author-infor">
                                        <div className="image">
                                            <img src={avt1} alt="AlJariyah" />
                                        </div>
                                        <span>by</span>
                                        <Link to="#">Masjid Sri Sendayan</Link>
                                        <span className="mr9">|</span>
                                        <span>July 29, 2024</span>

                                    </div>
                                </div>
                                <div className="image">
                                    <img src="https://al-jariyah.com/assets/images/kempen/banner-infaq-iftar.png" alt="AlJariyah" />
                                </div>
                                <div className="content-inner">
                                    {/* <h6 className="title">What Is The Most Fun Thing To Become A Designer?</h6> */}
                                    <p>Modul istimewa yang dibangunkan khusus untuk menampung perbelanjaan iftar semasa Ramadhan. Penginfaq boleh menaja kesemua atau sebahagian daripada jumlah harian kos penyediaan makanan iftar bagi institusi yang terlibat.</p><br/>
                                    {/* <p>The agreement was inked by Mr. Mohamed Hazli bin Mohamed Hussain, CEO of DagangTEK Sdn Bhd and Mr. Aizul Hisham Bin Mohd Dom the Manager of ANF Trade & Communications. This signing was witnessed by Mr. Zahari Azar Zainudin, Senior Vice President of DagangTEK Sdn Bhd. The session was also attended by representatives from DagangTEK Sdn Bhd.</p> */}
                                </div>
                                {/* <div className="image">
                                    <img src={img4} alt="AlJariyah" />
                                </div> */}
                                {/* <div className="image style-2">
                                    <img className="mr20" src={img2} alt="AlJariyah" />
                                    <img src={img3} alt="AlJariyah" />
                                </div> */}
                                {/* <div className="content-inner">
                                    <p>This collaboration agreement marks a significant step forward in empowering religious institutions across the country. By combining strengths and expertise, both parties are confident in achieving outstanding results and contributing positively to the communities they serve.</p><br/>
                                    <p>Both organizations believed digitalization of religious institution administrations will be a driving force in revolutionizing the way they operate and manage their resources, ultimately benefiting not only the institutions themselves but also the whole community in general.</p>
                                </div>
                                <div className="content-inner">
                                    <p>This momentous ceremony took place at DagangTEK’s headquarter office located in IOI Resort City, Putrajaya.</p>
                                </div> */}
                                
                            </div>                        
                        </div>                                     
                    </div>
                </div>
            </section>
            
        </div>
    );
}

export default BlogDetails01;