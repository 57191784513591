import React , {useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PageTitle from '../components/pagetitle/PageTitle2';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import avtd6 from '../assets/images/contactIcon/address.png'
import Data from '../assets/data/data-perintis'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';


ItemDetailsid1.propTypes = {
    
};

function ItemDetailsid1(props) {

    const [modalShow, setModalShow] = useState(false);
    const { slug } = useParams();
    const [itemData, setItemData] = useState(null);

    console.log(Data);
    console.log(slug);
    useEffect(() => {
        console.log('useEffect triggered');
        const itemData = Data.find((item) => item.slug === slug);
        console.log(itemData);
        setItemData(itemData);
      }, [slug]);
    
      if (!itemData) {
        return <div>Item not found or loading...</div>;
      }

    return (
        <div>

            <PageTitle sub='Senarai Institusi' title='Maklumat Institusi' />

            <section className="tf-item-detail">
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tf-item-detail-inner">
                                <div className="image" style={{flexShrink:"0", width:"35%"}}>
                                    <img src={itemData.img} alt="AlJariyah" style={{border:"groove"}}/> {/*img*/}
                                </div>
                                <div className="content">
                                    
                                    <h2 className="title-detail">{itemData.title}</h2> {/*title*/}
                                    <p className="except" style={{textAlign:"justify"}}>{itemData.desc}</p> {/*desc*/}

                                    <Tabs className="tf-tab">
                                        <TabList className="menu-tab ">
                                            <Tab className="tab-title active"><Link to="#">Maklumat Tambahan</Link></Tab>
                                            <Tab className="tab-title "><Link to="#">Contact</Link></Tab>
                                            <Tab className="tab-title "><Link to="#">Alamat</Link></Tab>
                                        </TabList>

                                        <TabPanel >
                                            <div className="tab-details">
                                                    <div className="top">   
                                                        <div className="author">
                                                            <div className="heading">{itemData.addDesc}</div> {/*addDesc*/}
                                                        </div>
                                                    </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel >
                                            <ul className="tab-bid">
                                                { itemData.contact.map((contactItem) => (
                                                        <li key={contactItem.id}> {/* data for contact information */}
                                                            <div className="box-bid">
                                                                <div className="image-bid">
                                                                    <img src={contactItem.avt} alt="AlJariyah" />
                                                                </div>
                                                                <div className="infor">
                                                                    <div className="history"><span className="price">{contactItem.title}</span></div>{/*title*/}
                                                                    <div className="time">{contactItem.item}</div>{/*item*/}
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
 
                                                </ul>
                                        </TabPanel>
                                        <TabPanel >
                                        <ul className="tab-bid">
                                                        <li>
                                                            <div className="box-bid">
                                                                <div className="image-bid">
                                                                    <img src={avtd6} alt="AlJariyah" />
                                                                </div>
                                                                <div className="infor">
                                                                    <div className="history"> <span className="name">{itemData.state}</span></div> {/*state*/}
                                                                    <div className="time">{itemData.address}</div> {/*address*/}
                                                                </div>
                                                            </div>
                                                        </li>
 
                                                </ul>
                                        </TabPanel>
                                    

                            </Tabs> 
         
                                    {/* <div className="content-bottom">
                                        <div className="heading">
                                            <h6>Jumlah Dana Terkumpul Tahun Ini</h6>
                                            <div className="price"><div className="icon"><i className="fa-solid fa-chart-line" style={{color:"#63E6BE"}}></i></div><span>RM 1ididid.idid</span></div>
                                        </div>
                                        <div className="button">
                                            <Link to="#" className="tf-button" data-toggle="modal" data-target="#popup_bid">Wishlist</Link>
                                            <Link to="#" className="tf-button">Infaq Now</Link>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="tf-section tf-top-seller">
    <div className="tf-container">
        <div className="row">
            <div className="col-md-12">
                <div className="tf-heading style-3 mb40 wow fadeInUp">
                    <h5 className="heading">Senarai Ahli Jawatankuasa</h5>
                    {/* <p className="sub-heading">The most creative creator - Based on the last 30 days </p> */}
                </div>
            </div>

            {itemData.jawatan.length === 0 ? (
                // Show this block if there are no records
                <div className="col-md-12">
                    <p style={{ textAlign: 'justify', fontSize: '18px', color: '#888' }}>
                        Tiada Rekod
                    </p>
                </div>
            ) : (
                // Render the list of committee members if there are records
                itemData.jawatan.map((jawatan) => (
                    <div key={jawatan.id} className="col-xl-3 col-lg-4 col-md-6">
                        <div className="tf-author-wrap">
                            <div className="tf-author" style={{ background: "white", border: "1px solid #E5E5E5" }}>
                                <div className="content">
                                    <div className="title">
                                        <Link to="#">{jawatan.title}</Link>
                                    </div>
                                    <div className="price">
                                        <span className="price-eth">{jawatan.item}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            )}
        </div>
    </div>
</section>


<section className="tf-section tf-news-events" style={{ paddingBottom: "90px" }}>
    <div className="tf-container">
        <div className="row">
            <div className="col-md-12">
                <div className="tf-heading style-3 mb40 wow fadeInUp">
                    <h5 className="heading">Hebahan Institusi</h5>
                    {/* <p className="sub-heading">Check out the latest news and events</p> */}
                </div>
            </div>
            <div className="col-md-12">
                {itemData.news.length === 0 ? (
                    // Display message if no news is available
                    <p style={{ textAlign: "justify", color: "#888", fontSize: "18px" }}>
                        Tiada Rekod
                    </p>
                ) : (
                    // Render Swiper if there are news items
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={50}
                        slidesPerView={3}
                        navigation
                        pagination={{ clickable: true }}
                        scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                    >
                        {itemData.news.map((newsItem, idx) => (
                            <SwiperSlide key={idx}>
                                <div className="news-card">
                                    <img src={newsItem.img} alt={newsItem.title} className="news-image" />
                                    <div className="news-content">
                                        <h6 className="news-title">{newsItem.title}</h6>
                                        <p className="news-description">{newsItem.desc}</p>
                                        <Link to={newsItem.link} className="read-more">Read more</Link>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )}
            </div>
        </div>
    </div>
</section>


            

            {/* <section className='tf-explore-more'>
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tf-heading">
                                <h4 className="heading">Senarai Institusi</h4>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row tf-filter-container wow fadeInUp" style={{ display: 'flex', flexWrap: 'wrap' }}>

                                {
                                    otherIndices.slice(0, 8).map((idx) => (
                                        <div key={idx}  className="col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber" style={{ flex: '1 0 25%' }}>
                                            <div className="sc-product style2" style={{ display: 'flex', flexDirection: 'column', height: '97%' }}>
                                                <div className="top" style={{ display: 'flex', flexDirection: 'column', height: '18%' }}>
                                                    <Link to="/keterangan-institusi" className="tag">{data[idx].title}</Link>
                                                    
                                                </div>
                                                
                                                <div className="features" style={{ flex: '1' }}>
                                                    <div className="product-media">
                                                        <img src={data[idx].img} alt="images" />
                                                    </div>
                                                    
                                                </div>
                                                <div className="bottom-style2">
                                                    <div className="product-button">
                                                        <Link to={`/keterangan-institusi/${idx}`} className="tf-button"> Maklumat Lanjut</Link>
                                                    </div>
                                                </div>
                
                                            </div>
                                        </div>
                                        
                                    ))
                                }
                            </div>
                        </div>

                    </div>
                </div>

                <CardModal 
                show={modalShow}
                onHide={() => setModalShow(false)} 
            />
            </section> */}
            
            
        </div>
    );
}

export default ItemDetailsid1;