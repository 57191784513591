import React , {useState} from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './style.scss';
import CardModal from '../layouts/CardModal';
// import { Dropdown } from 'react-bootstrap';


import { Link } from 'react-router-dom';

HotPick.propTypes = {
    data : PropTypes.array,
};

function HotPick(props) {

    const [modalShow, setModalShow] = useState(false);
    const {data} = props;

    const [dataTab] = useState([
        {
            id: 0,
            title: '3D MODEL',
            item: 0,
        },
        {
            id: 1,
            title: 'ANIME/MANGA',
            item: 4,
        },
        {
            id: 2,
            title: 'CYBER PUNK',
            item: 5,
        },
        {
            id: 3,
            title: 'PIXEL ART',
            item: 1,
        },
        {
            id: 4,
            title: 'MUSIC',
            item: 3,
        },
        {
            id: 5,
            title: 'ABSTRACT',
            item: 7,
        },
        {
            id: 6,
            title: '2D ARTS',
            item: 3,
        },

    ]);

    return (
        <section className="tf-section tf-hot-pick tf-filter">
                <div className="tf-container">
                    <div className="row ">
                        <div className="col-md-12">
                            <div className="tf-heading mb32 wow fadeInUp">
                                <h4 className="heading">Hot Picks</h4>
                            </div>
                        </div>
                        <div className="col-md-12">
                        <Tabs>

                            {
                                dataTab.map(idx => (
                                    <TabPanel key={idx.id}>
                                        <div className="row tf-filter-container wow fadeInUp" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {
                                                data.slice(idx.item, 10).map(idx => (
                                                    <div key={idx.id} className="col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber" style={{ flex: '1 0 25%' }}>
                                                        <div className="sc-product style2" style={{ display: 'flex', flexDirection: 'column', height: '97%' }}>
                                                            <div className="top" style={{ display: 'flex', flexDirection: 'column', height: '18%' }}>
                                                                <Link to="/item-details" className="tag">{idx.title}</Link>
                                                                
                                                            </div>
                                                           
                                                            <div className="features" style={{ flex: '1' }}>
                                                                <div className="product-media" >
                                                                    <img src={idx.img} alt="images"  />
                                                                </div>
                                                            </div>
                                                            <div className="bottom-style2">
                                                                
                                                                <div className="product-button">
                                                                    <Link to={`/institusi/${idx.slug}`} className="tf-button"> Maklumat Lanjut</Link>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        
                                        </div>
                                    </TabPanel>
                                ))
                            }
                            

                        </Tabs> 
              
                        </div>

                    </div>
                </div>

                <CardModal 
                show={modalShow}
                onHide={() => setModalShow(false)} 
            />
        </section>
    );
}

export default HotPick;